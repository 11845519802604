var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { convertInputToArray, makeRounds } from './group';
import Header from './Header';
import Footer from './Footer';
export default function App(__props) {
    // ユーザーが入力した名前の文字列
    var _a = React.useState('a,b,c,d'), inputMembers = _a[0], setInputMembers = _a[1];
    // 名前 => 分散させたいかどうかのフラグ
    var _b = React.useState({}), checkboxDict = _b[0], setCheckboxDict = _b[1];
    // グループの数
    var _c = React.useState(2), inputGroupCount = _c[0], setInputGroupCount = _c[1];
    // グループのメンバーの最大数
    var _d = React.useState(3), inputMaxMember = _d[0], setInputMaxMember = _d[1];
    // グループ数を指定して、グループを作成する。falseならメンバーの最大数を指定して、グループを作成する。
    var _e = React.useState(true), isGroupByGroupCount = _e[0], setIsGroupByGroupCount = _e[1];
    // モーダルの表示・非表示
    var _f = React.useState(false), isShowModal = _f[0], setIsShowModal = _f[1];
    // アウトプットで作成するグループの回数
    var _g = React.useState(1), roundCount = _g[0], setRoundCount = _g[1];
    // アウトプット
    var _h = React.useState([]), outputRounds = _h[0], setOutputRounds = _h[1];
    function onChangeInputMembers(e) { setInputMembers(e.target.value); }
    function onChangeInputGroupCount(e) { setInputGroupCount(e.target.value); }
    function onChangeInputMaxMember(e) { setInputMaxMember(e.target.value); }
    function onChangeIsGroupByGroupCount(e) { setIsGroupByGroupCount(e.target.checked); }
    function onChangeIsGroupByGroupCount2(e) { setIsGroupByGroupCount(!e.target.checked); }
    function onChangeRoundCount(e) { setRoundCount(e.target.value); }
    function onChangeCheckbox(e) {
        var _a;
        var _b = e.target, name = _b.name, checked = _b.checked;
        setCheckboxDict(__assign(__assign({}, checkboxDict), (_a = {}, _a[name] = checked, _a)));
    }
    var createGroupElements = function (groups) {
        return React.createElement("table", { className: 'groupContainer' }, groups.map(function (memberNames, index) {
            return React.createElement("tr", { key: 'group' + index, className: "output" }, memberNames.map(function (name, index2) {
                return React.createElement("td", { key: 'name' + index2, className: "name", align: 'center' }, name);
            }));
        }));
    };
    var roundElements = (React.createElement("div", null, outputRounds.map(function (groups, index) {
        return React.createElement("div", null,
            React.createElement("p", null,
                "\u30E9\u30A6\u30F3\u30C9",
                index + 1),
            createGroupElements(groups));
    })));
    var listInModal = (React.createElement("ul", null, convertInputToArray(inputMembers).map(function (name) { return React.createElement("li", { key: name },
        React.createElement("input", { type: "checkbox", id: name, name: name, checked: checkboxDict[name], onChange: onChangeCheckbox }),
        React.createElement("label", null, name)); })));
    var modal = (React.createElement("div", { style: { display: isShowModal ? 'block' : 'none' }, className: "modal" },
        React.createElement("p", null, "\u5206\u6563\u3055\u305B\u305F\u3044\u4EBA\u306B\u30C1\u30A7\u30C3\u30AF\u3092\u5165\u308C\u3066\u306D"),
        React.createElement("div", null, listInModal),
        React.createElement("button", { onClick: onClickHideModal }, "\u9589\u3058\u308B")));
    function onClickShowModal() { setIsShowModal(true); }
    function onClickHideModal() { setIsShowModal(false); }
    function onClick() {
        if (inputMembers) {
            var rounds = makeRounds(inputMembers, inputGroupCount, inputMaxMember, roundCount, isGroupByGroupCount, checkboxDict);
            // update rounds
            setOutputRounds(rounds);
        }
    }
    return (React.createElement("div", { className: "grouping-app" },
        modal,
        React.createElement(Header, null),
        React.createElement("div", null,
            React.createElement("h2", null, "\u30E1\u30F3\u30D0\u30FC\u306E\u540D\u524D\u3092\u5165\u308C\u308B(\u30AB\u30F3\u30DE\u307E\u305F\u306F\u6539\u884C\u533A\u5207\u308A)"),
            React.createElement("textarea", { className: 'textarea-inputMembers', id: "inputMembers", value: inputMembers, onChange: onChangeInputMembers, placeholder: "\u5C71\u7530,\u6751\u4E0A,\u9752\u6728,\u7530\u4E2D,\u83CA\u6C60,\u9234\u6728" }),
            React.createElement("div", null,
                React.createElement("button", { onClick: onClickShowModal }, "\u5206\u6563\u3055\u305B\u308B\u4EBA\u3092\u9078\u3076")),
            React.createElement("h2", null, "\u308F\u3051\u65B9\u3092\u6307\u5B9A\u3059\u308B(\u30B0\u30EB\u30FC\u30D7\u306E\u6570\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u30E1\u30F3\u30D0\u30FC\u306E\u6700\u5927\u6570)"),
            React.createElement("div", null,
                React.createElement("input", { type: "checkbox", id: 'isGroupByGroupCount', name: 'isGroupByGroupCount', checked: isGroupByGroupCount, onChange: onChangeIsGroupByGroupCount }),
                React.createElement("label", null, "\u30B0\u30EB\u30FC\u30D7\u306E\u6570\u3092\u6307\u5B9A\u3057\u3066\u5206\u3051\u308B"),
                React.createElement("input", { type: "number", id: "inputGroupCount", autoComplete: "off", value: inputGroupCount, onChange: onChangeInputGroupCount })),
            React.createElement("div", null,
                React.createElement("input", { type: "checkbox", id: 'isGroupByGroupCount2', name: 'isGroupByGroupCount2', checked: !isGroupByGroupCount, onChange: onChangeIsGroupByGroupCount2 }),
                React.createElement("label", null, "\u30B0\u30EB\u30FC\u30D7\u30E1\u30F3\u30D0\u30FC\u306E\u6700\u5927\u6570\u3092\u6307\u5B9A\u3057\u3066\u5206\u3051\u308B"),
                React.createElement("input", { type: "number", id: "inputMaxMember", autoComplete: "off", value: inputMaxMember, onChange: onChangeInputMaxMember })),
            React.createElement("h2", null, "\u30E9\u30A6\u30F3\u30C9\u6570\u3092\u6307\u5B9A\u3059\u308B"),
            React.createElement("input", { type: "number", id: "roundCount", autoComplete: "off", value: roundCount, onChange: onChangeRoundCount }),
            "\u56DE",
            React.createElement("div", null,
                React.createElement("button", { className: 'group-button', onClick: onClick }, "\u30B0\u30EB\u30FC\u30D7\u3092\u4F5C\u308B"))),
        React.createElement("div", { className: "output" },
            React.createElement("h2", null, "\u51FA\u529B"),
            React.createElement("div", { className: 'output-box' }, roundElements)),
        React.createElement(Footer, null)));
}
