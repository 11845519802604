var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function group(names, groupCount) {
    var groups = Array(groupCount).fill(0).map(function () { return []; });
    for (var i = 0; i < names.length; i++) {
        var groupIndex = i % groupCount;
        // なぜかgroups[groupIndex]がundefinedになることがあるためチェックする
        if (groups[groupIndex] === undefined) {
            groups[groupIndex] = [];
        }
        groups[groupIndex].push(names[i]);
    }
    return groups;
}
// Fisher–Yates shuffle
var shuffle = function (_a) {
    var _b;
    var array = _a.slice(0);
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        _b = [array[j], array[i]], array[i] = _b[0], array[j] = _b[1];
    }
    return array;
};
// convert comma separated or newline separated string to array
function convertInputToArray(input) {
    return input.trim()
        .split('\n')
        .map(function (name) { return name.split(','); })
        .flat()
        .map(function (name) { return name.trim(); })
        .filter(function (name) { return name.length > 0; });
}
var createData = function (members) {
    var createdData = {};
    members.forEach(function (member) {
        createdData[member] = {};
        members.forEach(function (member2) {
            createdData[member][member2] = 0;
        });
    });
    return createdData;
};
// @return new data
function createUpdatedData(data, round) {
    var newData = Object.assign({}, data);
    round.forEach(function (group) {
        group.forEach(function (member) {
            newData[member] = Object.assign({}, newData[member]);
            group.forEach(function (member2) {
                if (member !== member2) {
                    newData[member][member2]++;
                }
            });
        });
    });
    return newData;
}
function calculateVariances(data) {
    var variances = {};
    // not include self
    var size = Object.keys(data).length - 1;
    Object.keys(data).forEach(function (member) {
        variances[member] = 0;
        var avg = Object.values(data[member]).reduce(function (a, b) { return a + b; }, 0) / size;
        Object.keys(data[member]).forEach(function (member2) {
            variances[member] += Math.pow((data[member][member2] - avg), 2);
        });
        variances[member] /= size;
    });
    return variances;
}
// onClick のなかでよばれる
function makeRounds(inputMembers, inputGroupCount, inputMaxMember, roundCount, isGroupByGroupCount, checkboxDict) {
    var inputNames = convertInputToArray(inputMembers);
    // 分散させたい人、そうでない人を分けてそれぞれシャッフルする
    var checkedNames = inputNames.filter(function (name) { return checkboxDict[name]; });
    var uncheckedNames = inputNames.filter(function (name) { return !checkboxDict[name]; });
    // 分散させたい人を先頭にした配列にする
    var rounds = [];
    // 分散を計算するためのデータを作成する
    var data = createData(inputNames);
    // 作成するグループの数
    var groupCount = isGroupByGroupCount ? inputGroupCount : Math.ceil(inputNames.length / inputMaxMember);
    for (var i = 0; i < roundCount; i++) {
        // n回試して、最も分散が少ないグループを採用する
        var tryCount = 10;
        var leastVarianceGroups = void 0;
        // for debug
        var leastVariances = void 0;
        var leastMaxVariance = Number.MAX_VALUE;
        for (var j = 0; j < tryCount; j++) {
            var names = checkedNames.concat(shuffle(uncheckedNames));
            var groups = group(names, groupCount);
            // calculate variances
            var created = createUpdatedData(data, groups);
            var tempVariances = calculateVariances(created);
            // get max variance
            var tempMaxVariance = Math.max.apply(Math, Object.values(tempVariances));
            if (tempMaxVariance < leastMaxVariance) {
                leastMaxVariance = tempMaxVariance;
                leastVarianceGroups = groups;
                leastVariances = __assign({}, tempVariances);
            }
        }
        // for debug: print round count
        console.log({ round: i + 1 });
        // push least maxVariance group
        rounds.push(leastVarianceGroups);
        // update data
        data = createUpdatedData(data, leastVarianceGroups);
        // debug
        console.log('end of round');
        console.log(__assign({}, data));
        console.log({ leastVariances: leastVariances });
    }
    return rounds;
}
// export 
export { group, shuffle, convertInputToArray, createData, createUpdatedData, calculateVariances, makeRounds };
